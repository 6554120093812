<template>
  <div>
    <div v-if="hasData">
      <heading2
        :heading="balanceLabel"
        class="mb-0"
      />
      <b-row>
        <b-col cols="12" order-md="1" class="mb-3 mb-md-0 mt-md-3">
          <b-card>
            <div>
              <p class="mt-n1 mb-3 text-lg-right">
                <em>
                  {{ lastUpdate }}
                </em>
              </p>
            </div>
            <scoring-gauge
              :data="data"
              :buy-report-product="buyReportProduct"
            />
            <b-row class="click-pdf">
              <b-col cols="8">
                &nbsp;
              </b-col>
              <b-col cols="1">
                <b-button
                  class="btn-info ms-1"
                  @click="downloadFile"
                  v-if="canDownloadPdf"
                  v-b-tooltip.hover
                  :title="downloadTitle"
                >
                  <font-awesome-icon icon="cloud-download-alt" class="fa-fw"></font-awesome-icon>
                </b-button>
              </b-col>
              <b-col cols="3">
                <b-button
                  @click="createClickPdf"
                  variant="info"
                  size="md">
                  Genera PDF
                </b-button>
              </b-col>
            </b-row>

          </b-card>
        </b-col>
        <b-col cols="12">
          <balance-data :data="data" />
        </b-col>
      </b-row>
      <b-row v-if="hasNotes" class="margin-notes">
        <click-report-notes :data="clickSection" :scoring-label="scoringLabel"/>
      </b-row>
      <b-form-row class="h-100 mt-3">
        <b-col cols="12" md="6" class="d-flex mb-3 mb-md-0">
          <card-accent cta-card :title="creditLimitLabel" :subtitle="creditLimitData" />
        </b-col>
        <b-col cols="6" md="3" class="d-flex">
          <card-accent
            spotCard
            :spotValue="protestsSpotValue"
            :title="protestsDataTitle"
            :not-available="protestNotAvailable"
            doubleLineHeader
          />
        </b-col>
        <b-col cols="6" md="3" class="d-flex">
          <card-accent
            spotCard
            :spotValue="procsSpotValue"
            :title="procsDataTitle"
            :not-available="procsNotAvailable"
            doubleLineHeader
          />
        </b-col>
      </b-form-row>
<!--      <b-row>-->
<!--        <b-col xl="9" class="overflow-hidden">-->
<!--        </b-col>-->
<!--        <b-col xl="3" class="mt-3 mt-lg-0 d-lg-flex flex-lg-column justify-content-lg-center py-lg-5">-->
<!--          <b-form-row-->
<!--            class="h-100 d-flex flex-lg-column justify-content-lg-evenly align-items-stretch py-lg-3"-->
<!--          >-->
<!--            <b-col cols="6" lg="12" class="flex-grow-1">-->
<!--              <b-card class="card&#45;&#45;cta h-100" body-class="d-flex flex-column justify-content-center">-->
<!--                <div class="card&#45;&#45;cta__title">{{creditLimitLabel}}</div>-->
<!--                <div class="card&#45;&#45;cta__subtitle">{{creditLimitData}}</div>-->
<!--              </b-card>-->
<!--            </b-col>-->
<!--          </b-form-row>-->
<!--        </b-col>-->
<!--      </b-row>-->
      <product-wizard-modal
        :id="wizardModalId"
        :request-type="requestType"
        :request-product="itemKey"
        :product-description="productDescription"
        :entity-type="entityTypeData"
        :profile-entity="profileEntity"
      />
    </div>
    <div v-else class="py-3">
      <b-card>
        <template #header>
          <card-header
            :title="balanceLabelNa"
          />
        </template>
        <div>
          <p class="form-label">
            Non sono disponibili dati sufficienti per elaborare il documento richiesto. La ragione può essere o più una delle seguenti:
          </p>
          <ul class="form-label">
            <li>
              Il soggetto ricercato non ha mai depositato bilanci;
            </li>
            <li>
              Il soggetto ricercato non è una società di capitali (ad esempio, è una SAS o un’altra tipologia di società di persone);
            </li>
            <li>
              Il soggetto ricercato è un intermediario finanziario/mobiliare/assicurativo, che non deposita bilanci in
              formato civilistico.
            </li>
          </ul>
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>
import { isNotEmpty, isNotExist } from '@/utils/validators';
import {
  formatDateOnly,
  formatDateTime,
  formatKEuro,
} from '@/utils/formatterHelper';
import Heading2 from '@/components/clickCard/Heading2.vue';
import { getEntityApiBase, openPrivateDocument } from '@/utils/documents';
import CardHeader from '@/components/cardHeader/CardHeader.vue';

const ClickReportNotes = () => import('@/components/clickCard/ClickReportNotes.vue');
const BalanceData = () => import('@/components/clickCard/BalanceData.vue');
const CardAccent = () => import('@/components/clickCard/CardAccent.vue');
const ScoringGauge = () => import('@/components/scoringGauge/ScoringGauge.vue');
const ProductWizardModal = () => import('@/components/productTable/ProductWizardModal.vue');

export default {
  name: 'ProfileTabsDashboardMerits',
  components: {
    CardHeader,
    ClickReportNotes,
    Heading2,
    CardAccent,
    BalanceData,
    ScoringGauge,
    ProductWizardModal,
  },
  props: {
    data: Object,
  },
  data() {
    return {
      // eslint-disable-next-line global-require
      chartPlaceholder: require('@/assets/img/Report-CRP-chart_placeholder.png'),
      itemKey: 'pr_company_report_crp_class',
      productDescription: 'Report MF Solvency',
      entityTypeData: this.entityType || 'company',
      wizardModalId: this.modalId || 'ctaWizardModal',
      requestType: 'pr_company',
    };
  },
  computed: {
    notAvailableMsg() {
      return 'N.D.';
    },
    hasData() {
      return isNotEmpty(this.data?.scoring?.scoring);
    },
    profileEntity() {
      return {
        companyName: this.data?.info?.companyName,
        vatNumber: this.data?.info?.vatNumber,
        taxCode: this.data?.info?.taxCode,
        email: this.data?.attribute?.mainPecEmail?.contactData,
      };
    },
    creditLimitLabel() {
      return 'LIMITE DI CREDITO';
    },
    creditLimitData() {
      // console.log('this.data credit', this.data);
      if (isNotEmpty(this.data?.scoring?.creditLimit)) {
        // const val = formatDecimalNumber(this.data.scoring.creditLimit, 0, 'it');
        // return `${val} EUR`;
        return formatKEuro(this.data.scoring.creditLimit);
      }
      return this.notAvailableMsg;
    },
    // feedbackDataTitle() {
    //   return 'SEGNALAZIONI CRP';
    // },
    // pieValueOk() {
    //   const a = this.data?.score?.reportReceived?.globalVotes?.countPositive;
    //   const b = this.data?.score?.reportReceived?.globalVotes?.countTotal;
    //   const baseCheck = a / b;
    //   if (a > 0 && b > 0) return `${baseCheck * 100}%`;
    //   return this.notAvailableMsg;
    // },
    // pieValueKo() {
    //   const a = this.data?.score?.reportReceived?.globalVotes?.countNegative;
    //   const b = this.data?.score?.reportReceived?.globalVotes?.countTotal;
    //   const baseCheck = a / b;
    //   if (a > 0 && b > 0) return `${baseCheck * 100}%`;
    //   return this.notAvailableMsg;
    // },
    // pieValueKoBase360() {
    //   const a = this.data?.score?.reportReceived?.globalVotes?.countNegative;
    //   const b = this.data?.score?.reportReceived?.globalVotes?.countTotal;
    //   return (a / b) * 360;
    // },
    // pieSliceFirstSize() {
    //   if (this.pieValueKoBase360 > 180) return 'transform:rotate(180deg);';
    //   return `transform:rotate(${this.pieValueKoBase360}deg);`;
    // },
    // pieSecondSliceCheck() {
    //   return this.pieValueKoBase360 > 180;
    // },
    // pieSliceSecondSize() {
    //   const computed = this.pieValueKoBase360 - 180;
    //   return `transform:rotate(${computed}deg);`;
    // },
    // pieContainerClasses() {
    //   const baseClasses = 'pieContainer';
    //   if (this.data?.score?.reportReceived?.globalVotes?.countTotal === 0) return `${baseClasses} has-no-votes`;
    //   return baseClasses;
    // },
    protestsDataTitle() {
      return 'PROTESTI';
    },
    protestsSpotValue() {
      return !this.data?.additionalInfos?.protestFound;
    },
    protestNotAvailable() {
      return this.data?.additionalInfos?.protestFound === undefined;
    },
    procsDataTitle() {
      return 'FALLIMENTI E PROCEDURE';
    },
    procsSpotValue() {
      return !this.data?.scoring?.clickSection?.hasEvents;
    },
    procsNotAvailable() {
      return this.data?.scoring?.clickSection?.hasEvents === undefined;
    },
    lastUpdate() {
      const date = isNotEmpty(this.data.scoring?.lastUpdate) ? formatDateTime(this.data.scoring?.lastUpdate) : this.notAvailableMsg;
      return `(Ultimo aggiornamento scoring: ${date})`;
    },
    balanceLabel() {
      return `Ultimo Bilancio: ${this.balanceLastData}`;
    },
    balanceLastData() {
      if (this.getScoringData()?.lastBalanceDate) {
        const a = new Date(this.getScoringData().lastBalanceDate);
        return formatDateOnly(a, 'it');
        // const a = this.getScoringData().closeDate * 0.001;
        // return moment(a).format("DD/MM/YYYY");
      }
      return this.notAvailableMsg;
    },
    canDownloadPdf() {
      if (!this.data?.clickReportPdf) return false;

      const { lastUpdate } = this.data.clickReportPdf;
      if (isNotExist(lastUpdate)) return false;

      if (!this.data.scoring?.lastUpdate) return false;

      const lastClickPdfDate = new Date(lastUpdate);
      const lastScoringDate = new Date(this.data.scoring?.lastUpdate);
      return lastClickPdfDate >= lastScoringDate;
    },
    downloadTitle() {
      if (!this.data?.clickReportPdf) return 'Download Click PDF';

      const { lastUpdate } = this.data.clickReportPdf;
      if (isNotExist(lastUpdate)) return 'Download Click PDF';

      return `Download Click PDF del ${formatDateTime(lastUpdate, 'it')}`;
    },
    clickSection() {
      return this.data?.scoring?.clickSection;
    },
    hasNotes() {
      return this.data?.scoring?.clickSection?.hasInvalidState || this.data?.scoring?.clickSection?.hasDistancePenalty || false;
    },
    scoringLabel() {
      return this.data?.scoring?.scoringLabel || '';
    },
    balanceLabelNa() {
      return 'SCORING NON DISPONIBILE';
    },
  },
  methods: {
    getScoringData() {
      return this.data?.scoring?.clickSection || {};
    },
    buyReportProduct() {
      this.$bvModal.show(this.wizardModalId);
    },
    createClickPdf() {
      const currentProfile = this.$store.getters['subject/currentCompany'];
      let ownerRequestType;
      let ownerRequestId;

      if (isNotEmpty(currentProfile)) {
        ownerRequestType = 'COMPANY';
        ownerRequestId = currentProfile.companyId;
      } else {
        ownerRequestType = 'PERSON';
        ownerRequestId = this.$store.getters['subject/currentPerson'].personId;
      }
      if (this.data?.info?.vatNumber) {
        this.$store.dispatch('analysis/createClickReportPdf', {
          taxCode: this.data?.info?.vatNumber,
          ownerRequestType,
          ownerRequestId,
        }).then(() => {
          this.$confirm(
            {
              message: 'Il tuo documento è in fase di generazione. '
                + "Una volta completata l'operazione sarà disponibile sotto la voce 'I miei acquisti'.",
              button: {
                yes: 'Chiudi',
              },
            },
          );
        });
      }
    },
    downloadFile() {
      const reportDocId = this.data?.clickReportPdf?.documentId;
      if (isNotEmpty(reportDocId)) {
        openPrivateDocument(reportDocId, getEntityApiBase('USERANALYSIS'));
      }
    },
  },
};
</script>

<style scoped>

.click-pdf {
  margin-top: 10px;
}

.margin-notes {
  margin-top: 0.4rem;
}

</style>
